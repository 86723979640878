import React, { useState, useEffect, useContext, createContext } from "react";
import Router from "next/router";
import cookie from "js-cookie";
import { useQuery } from "react-query";

import {
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    sendPasswordResetEmail,
    confirmPasswordReset,
    sendEmailVerification,
    sendSignInLinkToEmail,
    signInWithEmailLink,
    isSignInWithEmailLink,
    onIdTokenChanged,
    signOut,
} from "firebase/auth";

import firebase from "./firebase";

import { createUser } from "./db";

const authContext = createContext();

export function AuthProvider({ children }) {
    const provideAuth = useProvideAuth();
    return <authContext.Provider value={provideAuth}>{children}</authContext.Provider>;
}

export const useAuth = () => {
    return useContext(authContext);
};

function useProvideAuth() {
    const [rawUser, setRawUser] = useState(null);
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);

    const handleUser = async (rawUser) => {
        if (rawUser) {
            //console.log("raw", rawUser);
            const _user = await formatUser(rawUser);

            setUser(_user);

            cookie.set("purple-door-finders-token", _user.token, {
                expires: 1,
            });

            setLoading(false);
            return _user;
        } else {
            setUser(false);
            cookie.remove("purple-door-finders-token");

            setLoading(false);
            return false;
        }
    };

    const signInWithEmail = async (email, password) => {
        setLoading(true);

        const response = await signInWithEmailAndPassword(firebase.auth, email, password);

        console.log("signInWithEmail", response.user);

        setRawUser(response.user);

        return handleUser(response.user);
    };

    const resendEmail = async () => {
        /*if (rawUser) {
            await sendEmailVerification(rawUser);
        }*/

        console.log("resendEmail", firebase.auth.currentUser);

        await sendEmailVerification(firebase.auth.currentUser);
    };

    const signInWithUserEmailLink = async (email) => {
        // store the email in local storage
        localStorage.setItem("emailForSignIn", email);

        console.log("signInWithUserEmailLink", email);

        // store the current URL in local storage
        localStorage.setItem("urlForSignIn", window.location.href);

        await sendSignInLinkToEmail(firebase.auth, email, {
            // URL to redirect back to (go to the home page for now)
            url: window.location.origin + "/finish-sign-in",
            handleCodeInApp: true,
        });
    };

    const checkSignInWithEmailLink = async (url) => {
        const email = localStorage.getItem("emailForSignIn");

        console.log("checkSignInWithEmailLink", email, url);

        if (isSignInWithEmailLink(firebase.auth, url)) {
            let response = await signInWithEmailLink(firebase.auth, email, url);

            window.localStorage.removeItem("emailForSignIn");

            const _user = handleUser(response.user);

            return _user;
        } else {
            return null;
        }
    };

    const createRandomPassword = () => {
        return (
            Math.random().toString(36).slice(-8) +
            Math.random().toString(36).slice(-8) +
            Math.random().toString(36).slice(-8)
        );
    };

    const signUp = async (
        email,
        password,
        name,
        receivedEmailUpdates,
        emailLink = false
    ) => {
        let response = await createUserWithEmailAndPassword(
            firebase.auth,
            email,
            emailLink ? createRandomPassword() : password
        );

        if (emailLink) {
            await sendSignInLinkToEmail(firebase.auth, email, {
                // URL to redirect back to (go to the home page for now)
                url: window.location.origin + "/finish-sign-in",
                handleCodeInApp: true,
            });
        } else {
            await sendEmailVerification(response.user);
        }

        setRawUser(response.user);

        const user = await handleUser(response.user);
        if (name) {
            user.name = name;
        }

        const { token, ...userWithoutToken } = user;

        try {
            if (receivedEmailUpdates) {
                await createUser(user.uid, {
                    ...userWithoutToken,
                    firstTime: true,
                    seniorLivingAndNewsletterUpdates: true,
                    searchAndPropertyUpdates: true,
                });
            } else {
                await createUser(user.uid, {
                    ...userWithoutToken,
                    firstTime: true,
                });
            }
        } catch (err) {
            console.log("createUser failed", err);
        }

        return user;
    };

    const sendPasswordResetEmailToUser = async (email) => {
        await sendPasswordResetEmail(firebase.auth, email);

        return true;
    };

    const confirmPasswordResetToUser = (password, code) => {
        const resetCode = code || getFromQueryString("oobCode");

        return confirmPasswordReset(firebase.auth, resetCode, password).then(() => {
            return true;
        });
    };

    const signInWithGoogle = async (redirect) => {
        setLoading(true);

        const response = await signInWithPopup(
            firebase.auth,
            new auth.GoogleAuthProvider()
        );

        handleUser(response.user);

        if (redirect) {
            Router.push(redirect);
        }
    };

    const signOutFunc = (url) => {
        Router.push(url ? url : "/");

        return signOut(firebase.auth).then(() => handleUser(false));
    };

    useEffect(() => {
        const unsubscribe = onIdTokenChanged(firebase.auth, handleUser);

        return () => unsubscribe();
    }, []);

    return {
        user,
        loading,
        signInWithEmail,
        signInWithUserEmailLink,
        signUp,
        sendPasswordResetEmailToUser,
        confirmPasswordResetToUser,
        checkSignInWithEmailLink,
        signInWithGoogle,
        signOut: signOutFunc,
        resendEmail,
    };
}

const formatUser = async (user) => {
    const token = await user.getIdToken();
    return {
        uid: user.uid,
        email: user.email,
        emailVerified: user.emailVerified,
        name: user.displayName,
        provider: user.providerData[0].providerId,
        photoUrl: user.photoURL,
        token,
    };
};

export const fetcherWithAuth = (url, token) => {
    return fetch(url, {
        method: "GET",
        headers: { token },
    }).then((res) => res.json());
};

export const fetchWithAuth = ({ queryKey }) => {
    const [url, token] = queryKey;

    return fetch(url, {
        method: "GET",
        headers: { token },
    }).then((res) => res.json());
};

export const useQueryWithToken = (url, token, initialData) => {
    let { data, refetch } = useQuery([url, token], fetchWithAuth, {
        initialData,
        enabled: token !== "",
    });

    if (token === "" || data === undefined) {
        data = initialData;
    }

    return { data, refetch };
};

export const updateCookie = (token) => {
    if (token) {
        cookie.set("purple-door-finders-token", token, {
            expires: 1,
        });
    }
};
