import React, { Fragment, useState } from "react";

import { Transition, Dialog } from "@headlessui/react";

import { useAuth } from "@/lib/auth";

import { XIcon, MailIcon } from "@heroicons/react/outline";
import { ExclamationIcon } from "@heroicons/react/solid";

import { IconButton } from "../ui/Button";
import PurpleDoorLogo from "@/components/Logo/PurpleDoorLogo";
import GoogleIcon from "@/components/Logo/Google";
import FacebookIcon from "@/components/Logo/Facebook";

const formatErrorMessage = (code) => {
    switch (code) {
        case "auth/invalid-email":
            return "Email is not valid";
        case "auth/wrong-password":
            return "Wrong Password";
        case "auth/user-not-found":
            return "Email not found";
        case "auth/weak-password":
            return "Password is too short (6+)";
        default:
            return code;
    }
};

const SignUpSignIn = ({
    openSignUp,
    openSignIn,
    setOpenSignUp,
    setOpenSignIn,
    onSuccessfulSignIn,
    onSignUpToCommunityPortal,
    nameIsRequired = true,
    communityPortal = false,
}) => {
    const auth = useAuth();
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [waitForEmail, setWaitForEmail] = useState(false);
    const [emailNotVerfied, setEmailNotVerfied] = useState(false);
    const [resetPassword, setResetPassword] = useState(false);
    const [error, setError] = useState("");
    const [receivedEmailUpdates, setReceivedEmailUpdates] = useState(true);

    const emailLink = communityPortal ? false : true;

    const handleClose = (success = false) => {
        if (communityPortal && !success) {
            // sign out the user - make sure they are not signed in.
            auth.signOut("/community-portal");

            // reload the page
            window.location.reload();
        } else {
            setPassword("");
            setError("");
            setOpenSignUp(false);
            setOpenSignIn(false);
            setResetPassword(false);
        }
    };

    const handleSwitch = () => {
        setError("");

        if (openSignUp) {
            setOpenSignUp(false);
            setOpenSignIn(true);
        } else {
            setOpenSignUp(true);
            setOpenSignIn(false);
        }

        setResetPassword(false);
    };

    const handleSignUp = async () => {
        try {
            setError("");

            if (!email) {
                setError("Email is required");
                return;
            } else if (!emailLink && !password) {
                setError("Password is required");
                return;
            } else if (nameIsRequired && !name) {
                setError("Full Name is required");
                return;
            }

            const user = await auth.signUp(
                email,
                password,
                name,
                communityPortal ? undefined : receivedEmailUpdates,
                emailLink
            );

            if (user && user.uid && onSignUpToCommunityPortal) {
                onSignUpToCommunityPortal(user.uid, email, name);
            }

            setWaitForEmail(true);
        } catch (err) {
            if (emailLink && err.code === "auth/email-already-in-use") {
                // sign in the user with email link
                await auth.signInWithUserEmailLink(email);

                setWaitForEmail(true);
                return;
            }

            console.log("handleSignUp failed", err);
            console.log("handleSignUp msg", err.message);
            console.log("handleSignUp code", err.code);
            setError(formatErrorMessage(err.code));
        }
    };

    const handleSignIn = async () => {
        try {
            setError("");
            setWaitForEmail(false);

            if (emailLink) {
                await auth.signInWithUserEmailLink(email);

                setWaitForEmail(true);
                return;
            }

            const user = await auth.signInWithEmail(email, password);
            if (user.provider === "password" && !user.emailVerified) {
                console.log(
                    "signed in successfully, but user.emailVerified",
                    user.emailVerified
                );

                setEmailNotVerfied(true);
            } else {
                handleClose(true);
                if (onSuccessfulSignIn) {
                    onSuccessfulSignIn(user.uid, user.email, user.name, user.token);
                }
            }
        } catch (err) {
            console.log("handleSignIn failed", err);
            console.log("handleSignIn msg", err.message);
            console.log("handleSignIn code", err.code);
            setError(formatErrorMessage(err.code));
        }
    };

    const handleResendEmail = async () => {
        await auth.resendEmail();
    };

    const handleSignInAfterVerification = async () => {
        if (communityPortal) {
            // sign out the user - make sure they are not signed in.
            auth.signOut("/community-portal");
        }

        setError("");
        setEmailNotVerfied(false);
        setWaitForEmail(false);
        setOpenSignUp(false);
        setOpenSignIn(true);
        setResetPassword(false);
    };

    const handleForgotPassword = async () => {
        setResetPassword(true);
        setError("");
        setWaitForEmail(false);
        setOpenSignUp(false);
        setOpenSignIn(true);
    };

    const handleSendPasswordResetEmail = async () => {
        try {
            await auth.sendPasswordResetEmailToUser(email);
        } catch (err) {
            console.log("handleSignIn failed", err);
            console.log("handleSignIn msg", err.message);
            console.log("handleSignIn code", err.code);
            setError(formatErrorMessage(err.code));
            return;
        }

        setResetPassword(false);
        setError("");
        setWaitForEmail(false);
        setOpenSignUp(false);
        setOpenSignIn(true);
    };

    return (
        <Transition appear show={openSignUp || openSignIn} as={Fragment}>
            <Dialog
                as="div"
                className="fixed inset-0 z-10 overflow-y-auto"
                onClose={() => {
                    //handleClose(false);
                }}
            >
                <div className="min-h-screen px-4 text-center">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed -z-10 inset-0 bg-black opacity-70" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className="inline-block h-screen align-middle" aria-hidden="true">
                        &#8203;
                    </span>

                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <div
                            className={`inline-block w-full ${
                                onSignUpToCommunityPortal ? "max-w-lg" : "max-w-md"
                            } px-12 pt-4 pb-8 overflow-hidden
                            text-left align-middle transition-all transform 
                            bg-white shadow-xl rounded-2xl`}
                        >
                            <div className="flex justify-end"></div>

                            <Dialog.Title
                                as="div"
                                className="mb-16 flex justify-between items-center"
                            >
                                <h3 className="text-2xl font-bold leading-6 text-primary-500">
                                    {openSignUp
                                        ? onSignUpToCommunityPortal !== undefined
                                            ? "Create Account (Communities)"
                                            : "Create Account"
                                        : "Welcome Back - Sign In"}
                                </h3>
                                <IconButton onClick={() => handleClose(false)}>
                                    <XIcon className="w-6 h-6" />
                                </IconButton>
                            </Dialog.Title>

                            {/*<div
                                className="scale-50"
                                style={{ marginLeft: -130, marginTop: -80 }}
                            >
                                <PurpleDoorLogo />
                            </div>*/}

                            {!emailLink && waitForEmail ? (
                                <div className="-mt-8 text-gray-800 space-y-2">
                                    <div className="font-bold">Follow these steps:</div>
                                    <div>
                                        1. Open the verification email that we sent you.
                                    </div>
                                    <div>2. Click on the verification link.</div>
                                    <div>
                                        3. Click{" "}
                                        <button
                                            className="text-primary-400 hover:underline font-bold"
                                            onClick={handleSignInAfterVerification}
                                        >
                                            Sign In
                                        </button>
                                    </div>
                                </div>
                            ) : null}

                            {emailLink && waitForEmail ? (
                                <div className="-mt-8 text-gray-800 space-y-2">
                                    <div className="font-bold">Follow these steps:</div>
                                    <div>
                                        1. Open the verification email that we sent you.
                                    </div>
                                    <div>
                                        2. If email is not in your inbox, check your spam
                                        folder.
                                    </div>
                                    <div>3. Click on the verification link to sign in.</div>
                                </div>
                            ) : null}

                            {emailNotVerfied ? (
                                <div className="-mt-8 text-gray-800 space-y-2">
                                    <div className="font-bold">
                                        Your email is not verified yet:
                                    </div>
                                    <div>
                                        1. Open the verification email that we sent you. If
                                        you didn't receive it, click here to{" "}
                                        <button
                                            className="text-primary-400 hover:underline font-bold"
                                            onClick={handleResendEmail}
                                        >
                                            resend email
                                        </button>
                                    </div>
                                    <div>2. Click on the verification link.</div>
                                    <div>
                                        3. Click{" "}
                                        <button
                                            className="text-primary-400 hover:underline font-bold"
                                            onClick={handleSignInAfterVerification}
                                        >
                                            Sign In
                                        </button>
                                    </div>
                                </div>
                            ) : null}

                            {resetPassword ? (
                                <>
                                    <div className="-mt-10">
                                        <h3 className="text-lg font-bold">
                                            Forgot Password?
                                        </h3>
                                        <p className="text-sm text-gray-800">
                                            1) Fill in your email and click on Send Email to
                                            Reset Password.
                                        </p>

                                        <p className="text-sm text-gray-800">
                                            2) Follow the email link and set a new password.
                                        </p>

                                        <p className="text-sm text-gray-800">
                                            3) Sign in with the new password.
                                        </p>
                                    </div>

                                    <div className="mt-4">
                                        <div className="">
                                            <label
                                                htmlFor="email"
                                                className="ml-0 text-gray-600 text-sm"
                                            >
                                                Email
                                            </label>
                                            <input
                                                id="email"
                                                type="email"
                                                name="email"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                                className="w-full bg-gray-100"
                                            />
                                        </div>
                                    </div>

                                    {error ? (
                                        <div className="mt-4">
                                            <div
                                                className={`w-full px-2 py-2 flex items-center justify-between
                                             text-white bg-red-500 rounded-md`}
                                            >
                                                <div className="flex items-center">
                                                    <ExclamationIcon className="w-6 h-6" />
                                                    <span className="ml-3 mr-2 font-bold">
                                                        Error:
                                                    </span>
                                                    <span>{error}</span>
                                                </div>
                                                <button onClick={() => setError("")}>
                                                    <XIcon className="w-6 h-6" />
                                                </button>
                                            </div>
                                        </div>
                                    ) : null}

                                    <div className="mt-4">
                                        <button
                                            type="button"
                                            className={`relative w-full px-4 py-2
                                        text-sm font-medium text-white bg-primary-500
                                        border border-transparent rounded-md
                                        hover:bg-primary-400
                                        focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2
                                        focus-visible:ring-primary-500`}
                                            onClick={handleSendPasswordResetEmail}
                                        >
                                            <div className="absolute top-2 left-2">
                                                <MailIcon width={20} height={20} />
                                            </div>
                                            Send Email to Reset Password
                                        </button>
                                    </div>

                                    <div className="mt-4">
                                        <span className="text-sm text-gray-600 font-light">
                                            {openSignUp
                                                ? "Already have an account? "
                                                : "Don't have an account? "}
                                        </span>
                                        <button
                                            className="text-sm text-primary-400 hover:underline font-bold"
                                            onClick={handleSwitch}
                                        >
                                            {openSignUp ? "sign in" : "sign up"}
                                        </button>
                                    </div>
                                </>
                            ) : null}

                            {!waitForEmail && !emailNotVerfied && !resetPassword ? (
                                <>
                                    <div className="-mt-10">
                                        {openSignUp ? (
                                            <div className="mb-2">
                                                <label
                                                    htmlFor="email"
                                                    className="ml-0 text-gray-600 text-sm"
                                                >
                                                    Full Name
                                                    {!nameIsRequired ? " (Optional)" : "*"}
                                                </label>
                                                <input
                                                    id="name"
                                                    type="text"
                                                    name="name"
                                                    value={name}
                                                    onChange={(e) =>
                                                        setName(e.target.value)
                                                    }
                                                    className="w-full bg-gray-100"
                                                />
                                            </div>
                                        ) : null}

                                        <div className="">
                                            <label
                                                htmlFor="email"
                                                className="ml-0 text-gray-600 text-sm"
                                            >
                                                Email*
                                            </label>
                                            <input
                                                id="email"
                                                type="email"
                                                name="email"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                                className="w-full bg-gray-100"
                                                required
                                            />
                                        </div>

                                        {emailLink ? (
                                            <div className="mt-2 text-sm text-gray-800">
                                                We'll send you an email to verify your email
                                                with a link to sign in.
                                            </div>
                                        ) : null}

                                        {!emailLink ? (
                                            <div className="mt-2">
                                                <label
                                                    htmlFor="password"
                                                    className="ml-0 text-gray-600 text-sm"
                                                >
                                                    Password*
                                                </label>
                                                <input
                                                    id="password"
                                                    type="password"
                                                    name="password"
                                                    value={password}
                                                    onChange={(e) =>
                                                        setPassword(e.target.value)
                                                    }
                                                    className="w-full bg-gray-100"
                                                    required
                                                />
                                            </div>
                                        ) : null}
                                    </div>

                                    {!emailLink && openSignIn ? (
                                        <div>
                                            <span className="text-sm text-gray-600 font-light">
                                                Forgot Password?{" "}
                                            </span>
                                            <button
                                                onClick={handleForgotPassword}
                                                className="text-sm text-primary-400 hover:underline font-bold"
                                            >
                                                Click here
                                            </button>
                                        </div>
                                    ) : null}

                                    {error ? (
                                        <div className="mt-4">
                                            <div
                                                className={`w-full px-2 py-2 flex items-center justify-between
                                                 text-white bg-red-500 rounded-md`}
                                            >
                                                <div className="flex items-center">
                                                    <ExclamationIcon className="w-6 h-6" />
                                                    <span className="ml-3 mr-2 font-bold">
                                                        Error:
                                                    </span>
                                                    <span>{error}</span>
                                                </div>
                                                <button onClick={() => setError("")}>
                                                    <XIcon className="w-6 h-6" />
                                                </button>
                                            </div>
                                        </div>
                                    ) : null}

                                    <div className="mt-4">
                                        <button
                                            type="button"
                                            className={`relative w-full px-4 py-2
                                            text-sm font-medium text-white bg-primary-500
                                            border border-transparent rounded-md
                                            hover:bg-primary-400
                                            focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2
                                            focus-visible:ring-primary-500`}
                                            onClick={
                                                openSignUp ? handleSignUp : handleSignIn
                                            }
                                        >
                                            <div className="absolute top-2 left-2">
                                                <MailIcon width={20} height={20} />
                                            </div>

                                            {openSignUp ? "Sign Up" : "Sign In"}
                                        </button>
                                    </div>

                                    {/*<div className="mt-2 w-full h-4 border-b border-gray-300 text-center">
                                        <span className="px-2 py-2 text-sm font-light text-gray-400 bg-white">
                                            or
                                        </span>
                                    </div>

                                    <div className="mt-4">
                                        <button
                                            type="button"
                                            className={`relative w-full px-4 py-2
                                            text-sm font-medium text-white bg-indigo-600
                                            border border-transparent rounded-md
                                            hover:bg-indigo-500
                                            focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2
                                            focus-visible:ring-primary-500`}
                                        >
                                            <div className="absolute top-2 left-2">
                                                <FacebookIcon width={20} height={20} />
                                            </div>
                                            <span className="ml-4">
                                                {openSignUp ? "Sign Up" : "Sign In"} with
                                                Facebook
                                            </span>
                                        </button>
                                    </div>

                                    <div className="mt-4">
                                        <button
                                            type="button"
                                            className={`relative w-full px-4 py-2
                                            text-sm font-medium text-gray-900 bg-gray-200
                                            border border-transparent rounded-md
                                            hover:bg-gray-300
                                            focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2
                                            focus-visible:ring-primary-500`}
                                        >
                                            <div className="absolute top-2.5 left-2">
                                                <GoogleIcon width={16} height={16} />
                                            </div>
                                            <span className="ml-4">
                                                {openSignUp ? "Sign Up" : "Sign In"} with
                                                Google
                                            </span>
                                        </button>
                                    </div>*/}

                                    <div className="mt-4">
                                        <span className="text-sm text-gray-600 font-light">
                                            {openSignUp
                                                ? "Already have an account? "
                                                : "Don't have an account? "}
                                        </span>
                                        <button
                                            className="text-sm text-primary-400 hover:underline font-bold"
                                            onClick={handleSwitch}
                                        >
                                            {openSignUp ? "sign in" : "sign up"}
                                        </button>
                                    </div>

                                    {!communityPortal && openSignUp ? (
                                        <div className="mt-4">
                                            <input
                                                type="checkbox"
                                                id="receivedEmailUpdates"
                                                name="receivedEmailUpdates"
                                                checked={receivedEmailUpdates}
                                                onChange={(e) =>
                                                    setReceivedEmailUpdates((x) => !x)
                                                }
                                            />
                                            <label
                                                htmlFor="receivedEmailUpdates"
                                                className="ml-2 text-sm text-gray-600"
                                            >
                                                Receive email updates
                                            </label>
                                        </div>
                                    ) : null}
                                </>
                            ) : null}
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition>
    );
};

export default SignUpSignIn;
