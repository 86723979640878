import React, { useEffect } from "react";
import { useRouter } from "next/router";
import { Provider } from "react-redux";
import { useStore } from "../store/store";
import { setFavourites, setExOut } from "../actions/localActions";
import { getFavourites, getExOut } from "../components/local/localStorage";
import { AuthProvider } from "@/lib/auth";
import { QueryClient, QueryClientProvider } from "react-query";
import FacebookPixel from "./FacebookPixel";

const google_tag = process.env.NEXT_PUBLIC_GOOGLE_TAG;

//import "tailwindcss/tailwind.css";
import "../styles/globals.css";

import Layout from "../components/common/Layout";

// create a client for react-query
const queryClient = new QueryClient();

function MyApp({ Component, pageProps }) {
    const router = useRouter();
    const store = useStore(pageProps.initialReduxState);

    // extract meta from pageProps
    let meta = {};
    if (pageProps.meta) {
        meta = pageProps.meta;
        //delete pageProps.meta; // Can't delete, it will not be available in the client
    }

    useEffect(() => {
        console.log("*************** init store *************");
        store.dispatch(setFavourites(getFavourites()));
        store.dispatch(setExOut(getExOut()));
    }, [store]);

    useEffect(() => {
        // Fires any time a route changes (including the first load)
        const handleRouteChange = (url) => {
            window.gtag("config", google_tag, {
                page_path: url,
            });

            // Now re-run the code that injects the iframe, etc.
            if (window.ldc && typeof window.ldc.init === "function") {
                window.ldc.init();
            }
        };

        router.events.on("routeChangeComplete", handleRouteChange);

        // Cleanup subscription on unmount
        return () => {
            router.events.off("routeChangeComplete", handleRouteChange);
        };
    }, [router.events]);

    return (
        <Provider store={store}>
            <QueryClientProvider client={queryClient}>
                <AuthProvider>
                    <Layout meta={meta}>
                        <FacebookPixel />
                        <Component {...pageProps} />
                    </Layout>
                </AuthProvider>
            </QueryClientProvider>
        </Provider>
    );
}

export default MyApp;
